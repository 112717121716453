import { Create } from '@material-ui/icons'
import React from 'react'
import TenderForm from './CreateTender'

export default function AdminTender() {
  return (
    <>
       <TenderForm/>
    </>
  )
}
